/**
 * @function
 * @param {object} state
 * @param {{type:string, payload: object}} action
 */
const defaultState = JSON.parse(window.localStorage.getItem("user") || "{}");
export const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case 'SET_USER':
            state = action.payload
            return state;
        default:
            return state;
    }
}

export const signInAction = (user) =>
    (dispatch) => {
        dispatch({ type: "SET_USER", payload: user });
        window.localStorage.setItem("user", JSON.stringify(user));
    }


export const signOutAction = () =>
    async (dispatch) => {
        // log out from server
        dispatch({ type: "SET_USER", payload: {} });
    }
