import React, { useState, useEffect } from "react";
import { Button, Box, TextField, Grid, Snackbar, Alert, FormControl, InputAdornment, IconButton, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { signInAction } from "../redux/auth";
import config from "../config";
import axios from "axios";

function Login() {
    const { auth } = useSelector((state) => state)
    const history = useHistory();
    const [input, setInput] = useState({ username: '', password: '' })
    const dispatch = useDispatch();
    const [message, setMessage] = useState('');
    const [errorOpen, setErrorOpen] = useState(false);
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [showPassword, setShowPassword] = useState(passwordFieldType !== 'password');


    function handleShowPassword() {
        showPassword ? setPasswordFieldType("text") : setPasswordFieldType("password")
        setShowPassword(!showPassword);
    }

    function login(e) {

        if (input.username.length === 0 || input.password.length === 0) {
            setMessage("Enter username and password");
            setErrorOpen(true);
            return;
        }

        axios.post(
            config.SOILCARE_API + "/auth/signin",
            input
        )
            .then(response => {
                const user = response.data.data;

                if (user.userProfile.role !== 'AGENT') {
                    setMessage("Please provide correct username and password");
                    setErrorOpen(true);
                    return;
                }

                dispatch(signInAction(response.data.data));
                history.push("/home");
            })
            .catch((error) => {
                const errorMsg = error.response.data.error;
                setMessage(errorMsg.details)
                setErrorOpen(true)
            })
    }

    useEffect(() => {
        if (auth.accessToken) {
            history.push("/home");
        }
    }, [])

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%"
            }}
            noValidate
            autoComplete="off"
        >
            <form style={{ width: "100%" }} onSubmit={(e) => { e.preventDefault(); login(e) }} >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={1} sm={3} md={3} lg={4}></Grid>
                    <Grid item xs={10} sm={6} md={6} lg={4}>

                        <div style={{ marginBottom: 20 }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Username"
                                size="small"
                                onKeyUp={(e) => setInput({ ...input, username: e.target.value })}
                                defaultValue=""
                            />
                        </div>
                        <div>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    label="Password"
                                    id="filled-adornment-password"
                                    type={passwordFieldType}
                                    //  onChange={(e) => setPassword(e.target.value)}
                                    onKeyUp={(e) => setInput({ ...input, password: e.target.value })}
                                    defaultValue=""
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => handleShowPassword()}
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                        </div>
                        <div style={{ marginTop: 20 }}>
                            <Button
                                variant="contained"
                                disableElevation
                                fullWidth
                                onClick={() => login()}
                                type="submit"
                            >
                                LOGIN
                            </Button>
                        </div>
                        <p style={{ textAlign: "center" }}> <Link to="/reset-password" style={{ textDecoration: "none" }}>Forgot password?</Link></p>
                    </Grid>
                    <Grid item xs={1} sm={3} md={3} lg={4}></Grid>
                </Grid>
            </form>
            <Snackbar open={errorOpen} onClose={() => setErrorOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert severity="error">{message}</Alert>
            </Snackbar>
        </Box>
    )
}

export default Login;
