import React, { useEffect, useState } from "react"
import { MyLocation } from "@mui/icons-material";
import Leaflet, { latLng } from "leaflet";
import "../../node_modules/leaflet/dist/leaflet.css"
import locationPin from "../icons/location-pin-alt.png";

let map;
let marker;
let circle;

function MapLocationPicker({ defaultLocation, onMapClick }) {
	const [currLocation, setCurrLocation] = useState(defaultLocation);
	const pinIcon = Leaflet.icon({
		iconUrl: locationPin,
		iconSize: [40, 40],
		iconAnchor: [18, 38],
	});

	function locate() {
		map.locate({ setView: true, enableHighAccuracy: true })
	}
	const osm = new Leaflet.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw', {
		attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
		id: 'mapbox/satellite-streets-v11',
		tileSize: 512,
		zoomOffset: -1
	})

	useEffect(() => {
		const width = document.getElementById('leaflet-map').offsetWidth;
		console.log(width)
		document.getElementById("leaflet-map").innerHTML = `<div id='map' style='height:400px; width:100%'></div>`
		map = Leaflet.map("map").locate({setView: true});

		map.invalidateSize(true);
		/* if (currLocation === defaultLocation)
			map.locate({ setView: true, enableHighAccuracy: true }); */
		osm.addTo(map)

		marker = Leaflet.marker(currLocation, { icon: pinIcon, draggable: true });
		marker.on("move", (e) => {
			setCurrLocation([e.latlng.lat, e.latlng.lng]);
			onMapClick(e.latlng);
		})
		marker.addTo(map);

		circle = Leaflet.circle(currLocation, { radius: 10});
		console.log(circle)
		circle.addTo(map);

		map.on('locationfound', (e) => {
			const latLng =  e.latlng
			setCurrLocation([latLng.lat, latLng.lng])
			marker.setLatLng(latLng)
			circle.setLatLng(latLng).setRadius(e.accuracy);
			
		});
		map.on('locationerror', (e) => console.log(e));
	}, [])

	return (
		<div style={{ position: "relative", height: "400px" }}>
			<button
				onClick={() => locate()}
				style={{
					position: "absolute",
					top: "10px",
					right: "10px",
					zIndex: 450,
					height: "34px",
					width: "34px",
					background: "#fff",
					border: "2px solid rgba(0, 0, 0, .2)",
					borderRadius: "10%",
					textAlign: "center"
				}}><MyLocation style={{ fontSize: "19px" }} /></button>
			<div id="leaflet-map" style={{ width: "100%" }}></div>
		</div>
	)
}

export default MapLocationPicker;
