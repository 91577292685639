import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from "@mui/material";
import * as serviceWorker from './serviceWorker';

const theme = createTheme({
    palette: {
        primary: {
            main: "#1fa405",
        },
        error: {
            main: "#fc0416",
        },
    },
});


ReactDOM.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
    ,
    document.getElementById('root'));
serviceWorker.register();
