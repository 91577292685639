import React from "react";
import {
	Button,
	Typography,
	Box
} from "@mui/material";
import { useHistory } from "react-router-dom";
import Header from "../header";

function Home() {
	const history = useHistory();
	return (
		<div style={{display: "flex", flexFlow: "column", height: "100%"}}>
			<Header title="HOME" />
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					width: "100%",
					flex: 1
				}}
			>
				<Button
					variant="outlined"
					style={{ height: "150px", width: "150px", borderRadius: "50%" }}
					onClick={() => history.push("/test")}
				>
					<Typography style={{ fontSize: 24, fontWeight: "bold" }}>START TESTING</Typography>
				</Button>
			</Box>
		</div>
	)
}

export default Home;
