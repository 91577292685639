import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import "./App.css";
import LoginPage from "./views/Login";
import HomePage from "./views/Home";
import TestPage from "./views/TestData";
import SavedDataPage from "./views/SavedData";
import store from './redux/store'
import { Provider } from 'react-redux'

class App extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <LoginPage />
                        </Route>
                        <Route exact path="/home">
                            <HomePage />
                        </Route>
                        <Route exact path="/test">
                            <TestPage />
                        </Route>
                        <Route exact path="/saved-data">
                            <SavedDataPage />
                        </Route>
                    </Switch>
                </Router>
            </Provider>
        );
    }
}

export default App;
