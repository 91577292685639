export const sensorConnected = (state = false, action) => {
	switch (action.type) {
		case 'SET_CONN':
			state = action.payload;
			return state;
		default:
			return state;
	}
}

export const setSensorConnection = (value) =>
	(dispatch) => {
		dispatch({ type: 'SET_CONN', payload: value })
	}
