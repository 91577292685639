import { configureStore } from '@reduxjs/toolkit';
import { composeWithDevTools } from "redux-devtools-extension";
import { sensorReducer } from "./sensor";
import { sensorDataReducer } from "./sensor-data";
import { sensorConnected } from "./connected";
import { authReducer } from "./auth";
export default configureStore({
	reducer: {
		sensor: sensorReducer,
		sensorData: sensorDataReducer,
		sensorConnected: sensorConnected,
		auth: authReducer
	},
}, composeWithDevTools())
