

export const sensorReducer = (state = "7 in 1", action) => {
	switch (action.type) {
		case 'SET_SENSOR':
			state = action.payload;
			return state;
		default:
			return state;
	}
}

export const setSensor = (value) =>
	(dispatch) => {
		dispatch({ type: 'SET_SENSOR', payload: value })
	}
