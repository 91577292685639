
const defaultValue = {
	nitrogen: '',
	phosphorus: '',
	potassium: '',
	ph: '',
	ec: '',
	temp: '',
	humid: ''
}

export const sensorDataReducer = (state = defaultValue, action) => {
	switch (action.type) {
		case 'SET_SENSOR_DATA':
			state = action.payload;
			return state;
		default:
			return state;
	}
}

export const setSensorData = (value) =>
	(dispatch) => {
		dispatch({ type: 'SET_SENSOR_DATA', payload: value })
	}
