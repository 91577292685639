import React, { useEffect, useState } from "react"
import {
    TextField, Autocomplete,
    Accordion, AccordionDetails, AccordionSummary,
    Typography,
    Grid, Snackbar, Alert, Card
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { ExpandMore, Send } from "@mui/icons-material";
import Header from "../header";
import "./TestData.css";
import { useSelector } from 'react-redux'
import axios from "axios";
import config from "../config";
import MapLocationPicker from "./MapLocationPicker";

const defaultLocation = [26.1840197, 91.7831068]

function TestData() {
    const { sensor, sensorConnected, auth } = useSelector((state) => state)
    const [expanded, setExpanded] = useState('panel2');
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [addressData, setAddressData] = useState({
        states: [],
        districts: [],
        subdistricts: [],
        village: [],
        customers: []
    });
    const defaultValue = {
        nitrogen: '',
        phosphorus: '',
        potassium: '',
        ph: '',
        ec: '',
        temp: '',
        humid: ''
    }
    const [sensorData, setSensorData] = useState(defaultValue)
    const [selectedOptions, setSelectedOptions] = useState({})
    const [fieldType, setFiledType] = useState('');
    const [latlng, setLatLng] = useState(defaultLocation)
    const [remarks, setRemarks] = useState('');
    const [landArea, setLandArea] = useState('');
    const [message, setMessage] = useState('');
    const [msgtype, setMsgType] = useState('error');

    const handleChange = (panel) => (_event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const pushToSavedData = (data, message) => {
        let savedItems = window.localStorage.getItem("saved-items") || "[]";
        savedItems = JSON.parse(savedItems);
        savedItems.push(data);
        window.localStorage.setItem("saved-items", JSON.stringify(savedItems));
        setMsgType("error")
        setMessage(message);
        setSnackbarOpen(true);
        setLoading(false);
    }

    const sendData = async () => {
        if (Object.entries(selectedOptions).length !== 5) {
            setMsgType("error");
            setMessage("Please select customer and land address");
            setSnackbarOpen(true);
            return;
        }
        setLoading(true);
        const data = {
            samples: [{
                nitrogen: sensorData.nitrogen,
                phosphorus: sensorData.phosphorus,
                potassium: sensorData.potassium,
                ph: sensorData.ph,
                ec: sensorData.ec,
                temperature: sensorData.temp,
                moisture: sensorData.humid
            }],
            landAreaSize: landArea,
            location: latlng,
            state: selectedOptions.state.name,
            district: selectedOptions.district.name,
            subdistrict: selectedOptions.subdistrict.name,
            village: selectedOptions.village.name,
            customerId: selectedOptions.customer.id,
            additionalParameters: {
                remarks: remarks,
                fieldType: fieldType,
                testedAt: Date.now()
            }

        };

        try {
            if (!navigator.onLine) {
                pushToSavedData(data, "Device offline, Test result saved to draft");
                return;
            }
            await axios.post(config.SOILCARE_API + "/test-results", data, {
                headers: {
                    "X-Auth-Token": auth.accessToken
                }
            })
            setMsgType("success")
            setMessage("Data sent successfully")
            setSnackbarOpen(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            pushToSavedData(data, "Error occured while sending, Test result saved to draft");
            setMsgType("error")
            setMessage("Unable to send data to server, Data saved as draft")
            setSnackbarOpen(true);
            setLoading(false);
        }
    }

    const fetchCustomers = async () => {
        try {
            const response = await axios.get(
                config.SOILCARE_API + "/customers/active",
                {
                    headers: {
                        "X-Auth-Token": auth.accessToken
                    }
                }
            );
            let a = addressData;
            a.customers = response.data.data;
            setAddressData(a)
        } catch (error) {
            console.log(error);
        }
    }

    const fetchStates = async () => {
        try {
            const response = await axios.get(config.ADDRESS_API + "/states");
            setAddressData({ ...addressData, ...{ states: response.data } });
        } catch (error) {
            console.log(error);
        }
    }

    const fetchDistrict = async (state) => {
        try {
            const response = await axios.get(config.ADDRESS_API + `/districts/state/${state.id}`);
            setAddressData({ ...addressData, ...{ districts: response.data } });
        } catch (error) {
            console.log(error);
        }
    }

    const fetchSubdistrict = async (district) => {
        try {
            const response = await axios.get(config.ADDRESS_API + `/subdistricts/state/${district.stateId}/district/${district.id}`);
            setAddressData({ ...addressData, ...{ subdistricts: response.data } });
        } catch (error) {
            console.log(error);
        }
    }

    const fetchVillage = async (subdistrict) => {
        try {
            const response = await axios.get(config.ADDRESS_API + `/villages/state/${subdistrict.stateId}/district/${subdistrict.districtId}/subdistrict/${subdistrict.id}`);
            setAddressData({ ...addressData, ...{ village: response.data } });
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (addressData.customers.length === 0)
            fetchCustomers().then(() => fetchStates());

    }, [sensor, sensorData, sensorConnected])

    return (
        <div>
            <Header title="ADD TEST " />
            <div style={{ padding: "2%", background: "transparent" }}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            Customer and Land address
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item lg={3} xs={12} sm={6} md={4}>
                                <Autocomplete fullWidth
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    getOptionLabel={(option) => option.fullName}
                                    options={addressData.customers}
                                    onChange={(e, v) => {
                                        setSelectedOptions({ ...selectedOptions, ...{ customer: v } })
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} size="small" variant="outlined" label="CUSTOMER" />
                                    }
                                />
                            </Grid>
                            <Grid item lg={3} xs={12} sm={6} md={4}>
                                <Autocomplete fullWidth
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    options={addressData.states}
                                    onChange={(e, v) => {
                                        setSelectedOptions({ ...selectedOptions, ...{ state: v } })
                                        fetchDistrict(v)
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} size="small" variant="outlined" label="STATE" />
                                    }
                                />
                            </Grid>
                            <Grid item lg={3} xs={12} sm={6} md={4}>
                                <Autocomplete fullWidth
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    options={addressData.districts}
                                    onChange={(e, v) => {
                                        setSelectedOptions({ ...selectedOptions, ...{ district: v } });
                                        fetchSubdistrict(v);
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} size="small" variant="outlined" label="DISTRICT" />
                                    }
                                />
                            </Grid>
                            <Grid item lg={3} xs={12} sm={6} md={4}>
                                <Autocomplete fullWidth
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    options={addressData.subdistricts}
                                    onChange={(e, v) => {
                                        setSelectedOptions({ ...selectedOptions, ...{ subdistrict: v } });
                                        fetchVillage(v)
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} size="small" variant="outlined" label="SUBDISTRICT" />
                                    }
                                />
                            </Grid>
                            <Grid item lg={3} xs={12} sm={6} md={4}>
                                <Autocomplete fullWidth
                                    isOptionEqualToValue={(option, value) => {
                                        return option.id === value.id
                                    }}
                                    getOptionLabel={(option) => option.name}
                                    options={addressData.village}
                                    onChange={(e, v) => {
                                        const a = selectedOptions;
                                        a.village = v
                                        setSelectedOptions(a);
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params} size="small" variant="outlined" label="VILLAGE" />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography>
                            Test values
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" size="small" label="NITROGEN"
                                    inputProps={{ inputMode: "numeric" }}
                                    onKeyUp={(e) => setSensorData({ ...sensorData, ...{ nitrogen: e.target.value } })}
                                />
                            </Grid>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" size="small" label="PHOSPHORUS" inputMode="numeric"
                                    inputProps={{ inputMode: "numeric" }}
                                    onKeyUp={(e) => setSensorData({ ...sensorData, ...{ phosphorus: e.target.value } })}
                                />
                            </Grid>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" size="small" label="POTASSIUM" inputMode="numeric"
                                    inputProps={{ inputMode: "numeric" }}
                                    onKeyUp={(e) => setSensorData({ ...sensorData, ...{ potassium: e.target.value } })}
                                />
                            </Grid>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" size="small" label="EC" inputMode="numeric"
                                    inputProps={{ inputMode: "numeric" }}
                                    onKeyUp={(e) => setSensorData({ ...sensorData, ...{ ec: e.target.value } })}
                                />
                            </Grid>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" size="small" label="pH" inputMode="numeric"
                                    inputProps={{ inputMode: "numeric" }}
                                    onKeyUp={(e) => setSensorData({ ...sensorData, ...{ ph: e.target.value } })}
                                />
                            </Grid>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" size="small" label="TEMP" inputMode="numeric"
                                    inputProps={{ inputMode: "numeric" }}
                                    onKeyUp={(e) => setSensorData({ ...sensorData, ...{ temp: e.target.value } })}
                                />
                            </Grid>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" size="small" label="MOSITURE" inputMode="numeric"
                                    inputProps={{ inputMode: "numeric" }}
                                    onKeyUp={(e) => setSensorData({ ...sensorData, ...{ humid: e.target.value } })}
                                />
                            </Grid>
                            <Grid item lg={3} xs={6} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" onKeyUp={(e) => setLandArea(e.target.value)} size="small" label="LAND AREA" />
                            </Grid>
                            <Grid item lg={3} xs={12} sm={6} md={4} >
                                <Autocomplete fullWidth options={["Field", "Greenhouse"]}
                                    disableClearable
                                    onChange={(e, v) => setFiledType(v)}
                                    renderInput={(params) =>
                                        <TextField {...params} size="small" variant="outlined" label="LAND TYPE" />
                                    }
                                />
                            </Grid>
                            <Grid item lg={3} xs={12} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" onKeyUp={(e) => setRemarks(e.target.value)} size="small" label="REMARKS" />
                            </Grid>
                            <Grid item lg={3} xs={12} sm={6} md={4}>
                                <TextField fullWidth variant="outlined" value={latlng} size="small" label="LATLNG" />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Card sx={{ width: "95vw", marginTop: "10px" }}>
                            <MapLocationPicker defaultLocation={defaultLocation} onMapClick={(v) => setLatLng([v.lat, v.lng])} />
                        </Card>
                    </Grid>
                    <Grid item lg={12} xs={12} sm={12} md={12}>
                        <LoadingButton loading={loading} variant="contained" onClick={() => sendData()} startIcon={<Send />} disableElevation fullWidth>SEND TO SERVER</LoadingButton>
                    </Grid>
                </Grid>
            </div>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={msgtype} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div >
    )
}

export default TestData;
