import React, { useState } from "react";
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Drawer,
    List, ListItemButton, ListItemIcon, ListItemText,
    Avatar, Divider
} from "@mui/material";
import { KeyboardArrowLeft, Menu, Settings, Logout, Storage } from "@mui/icons-material";
import { deepPurple } from '@mui/material/colors';

import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { signInAction } from "./redux/auth";


function Header({ title }) {
    const history = useHistory();
    const [state, setState] = useState(false);
    const user = useSelector((state) => state.auth.userProfile)
    const dispatch = useDispatch();
    const drawerWidth = 250;

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: deepPurple[500],
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const logOut = () => {
        dispatch(signInAction({}));
        history.push("/");
    }

    return (
        <div>
            <AppBar position="sticky">
                <Toolbar>

                    {window.location.pathname !== "/home" && <IconButton size="large" edge="start" color="inherit" aria-label="back"
                        onClick={() => history.goBack()}
                    ><KeyboardArrowLeft /></IconButton>
                    }
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, textAlign: "left" }}
                    >
                        {title}
                    </Typography>
                    <IconButton size="large" edge="end" color="inherit" aria-label="menu" onClick={() => setState(true)}> <Menu /> </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer open={state} anchor="right" onClose={() => setState(false)}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <div style={{ height: 120, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Avatar {...stringAvatar("John Doe")} />
                    <Typography>{user.name}</Typography>
                    <Typography><small>{user.email}</small></Typography>
                </div>
                <Divider />
                <List>
                    <ListItemButton
                        onClick={() => history.push("/saved-data")}
                    >
                        <ListItemIcon><Storage /></ListItemIcon><ListItemText>Saved Tests</ListItemText>
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => logOut()}
                    ><ListItemIcon>
                            <Logout /></ListItemIcon><ListItemText>Signout</ListItemText>
                    </ListItemButton>
                </List>
            </Drawer>
        </div >
    )
}

export default Header;
