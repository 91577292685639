import React, { useEffect, useState } from "react";
import {
    Button, Drawer, Snackbar, Alert,
    List, ListItem,
    ListItemText, ListItemButton,
    Divider,
    Grid,
    TextField,
    Card
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { useSelector } from 'react-redux'
import Header from "../header";
import MapLocationPicker from "./MapLocationPicker";
import axios from "axios";
import config from "../config";

const defaultLocation = [26.1840197, 91.7831068]

function SavedData() {
    const { auth } = useSelector((state) => state)
    const [items, setItems] = useState(JSON.parse(window.localStorage.getItem("saved-items") || "[]"));
    const [selectedItem, setSelectedItem] = useState({});
    const [openDrawer, setDrawerOpen] = useState(false);
    const [latlng, setLatLng] = useState(defaultLocation);
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [message, setMessage] = useState('');
    const [msgtype, setMsgType] = useState('error');

    const formatDate = (date) => {
        const dString = new Intl.DateTimeFormat("en-In", {
            year: 'numeric', month: 'long', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
        }).format(date);

        return dString.replace("IST", "");
    }

    const getNPKString = (sample) => {
        return `N${sample.nitrogen}-P${sample.phosphorus}-K${sample.potassium}`
    }

    const setChangetLatLng = (v) => {
        setLatLng(v.split(","))
    }

    const displayItem = () => {
        const fileds = [];
        for (const key in selectedItem) {
            if (Array.isArray(selectedItem[key])) {
                selectedItem[key].forEach((f) => {
                    for (const k in f) {
                        fileds.push(
                            <Grid item xs={6} sm={6} md={6} lg={4}>
                                <TextField readOnly size="small" fullWidth label={k.toUpperCase()} value={f[k]} />
                            </Grid>
                        )
                    }
                })

                continue;
            }

            if (selectedItem[key] === Object(selectedItem[key])) {
                for (const j in selectedItem[key]) {
                    if (j === 'testedAt') {
                        fileds.push(
                            <Grid item xs={6} sm={6} md={6} lg={4}>
                                <TextField readOnly size="small" fullWidth label={j.toUpperCase()} value={formatDate(selectedItem[key][j])} />
                            </Grid>
                        )
                    } else {
                        fileds.push(
                            <Grid item xs={6} sm={6} md={6} lg={4}>
                                <TextField readOnly size="small" fullWidth label={j.toUpperCase()} value={selectedItem[key][j]} />
                            </Grid>
                        )
                    }
                }
                continue;
            }

            fileds.push(
                <Grid item xs={6} sm={6} md={6} lg={4}>
                    <TextField size="small" readOnly fullWidth label={key.toUpperCase()} value={selectedItem[key]} />
                </Grid>
            )
        }

        return fileds;
    }

    const updateLocalStorage = (testedAt) => {
        const oldItems = items;
        const newItems = oldItems.filter((item) => {
            return item.additionalParameters.testedAt !== testedAt
        });
        window.localStorage.setItem("saved-items", JSON.stringify(newItems));
        setItems(newItems);
    }

    const sendData = async () => {
        const item = selectedItem;
        item.location = latlng;
        try {
            if (!navigator.onLine) {
                setMsgType("error")
                setMessage("Device offline, Please connect the device to a network")
                setSnackbarOpen(true);
                return
            }
            await axios.post(config.SOILCARE_API + "/test-results", item, {
                headers: {
                    "X-Auth-Token": auth.accessToken
                }
            })
            updateLocalStorage(item.additionalParameters.testedAt);
            setDrawerOpen(false);
            setMsgType("success")
            setMessage("Data sent successfully")
            setSnackbarOpen(true);
        } catch (error) {
            console.log(error);
            setMsgType("error")
            setMessage("Unable to send data to server, Data saved as draft")
            setSnackbarOpen(true);
        }
    }

    return (
        <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
            <Header title="SAVED TESTS" />
            <List sx={{ flex: 1, overflowY: "auto" }}>
                {items.map((item, idx) => (
                    <>
                        <ListItemButton style={{ padding: 2 }} onClick={() => { setSelectedItem(item); setDrawerOpen(true) }}>
                            <ListItem>
                                <ListItemText
                                    primary={getNPKString(item.samples[0])}
                                    secondary={formatDate(item.additionalParameters.testedAt)}
                                />
                            </ListItem>
                        </ListItemButton>
                        <Divider component="li" />
                    </>
                ))}
            </List>
            <Drawer open={openDrawer} anchor="bottom" onClose={() => setDrawerOpen(false)}
                sx={{
                    width: "100%",
                    height: "80%",
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: "100%", height: "80%", boxSizing: 'border-box' },
                }}
            >
                <Grid container spacing={2} sx={{ padding: "4% 2% 4% 2%" }}>
                    {displayItem()}

                    <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField 
                            size="small" 
                            fullWidth label="LATLNG" 
                            defaultValue={latlng || ""}
                            onChange={(e) => setChangetLatLng(e.target.value)}
                            helperText="Format: latitute, longitute"
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Card>
                            <MapLocationPicker defaultLocation={latlng} onMapClick={(v) => setLatLng([v.lat, v.lng])} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Button onClick={() => sendData()} startIcon={<Send />} variant="contained" size="large" fullWidth>SEND TO SERVER </Button>
                    </Grid>
                </Grid>
            </Drawer>

            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={msgtype} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default SavedData;
